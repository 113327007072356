import {
  Box, SkeletonCircle, Flex, useTheme, Text,
} from '@chakra-ui/react';

function CircleIndicator({
  colour, value, size, isLoading, padding,
}) {
  const fontSize = value?.length > 5 ? 'xl' : '3xl';
  const shouldDisplayValue = value !== null && value !== undefined
    && (typeof value === 'number' || (typeof value === 'string' && !(value.includes('No data') || value.includes('null') || value.includes('NaN'))));
  const radius = size / 2;
  const theme = useTheme();

  // Safely resolve the Chakra color
  let resolvedColour = colour || theme.colors.gray[200];
  if (typeof colour === 'string' && colour.includes('.')) {
    const [colorKey, shade] = colour.split('.');
    resolvedColour = theme.colors[colorKey]?.[shade];
  }

  return (
    <Box padding={padding || 2} className="indicator">
      {isLoading && <SkeletonCircle size={`${size}px`} />}
      {!isLoading && (
        <Box position="relative" width={`${size}px`} height={`${size}px`}>
          <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx={radius}
              cy={radius}
              r={radius}
              fill={resolvedColour}
              stroke="none"
            />
          </svg>
          {shouldDisplayValue && (
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              transform={`translate(-50%, -50%) scale(${size / 100})`}
              align="center"
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={fontSize}
                textShadow="1px 1px 2px rgba(0,0,0,0.2)"
              >
                {value}
              </Text>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CircleIndicator;
