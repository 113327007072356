import {
  Flex, Container, VStack, Text,
} from '@chakra-ui/react';
import Filter from '@tce/components/pages/traffic-count-estimates/components/wizard/Filter';
import {
  agTrafficMixColdef,
  dateFormatter,
} from '@tce/components/pages/traffic-count-estimates/components/wizard/table';
import TceGrid from '@tce/components/pages/traffic-count-estimates/components/wizard/TceGrid';

const screenName = 'step5';

function DataTable() {
  const columnDefs = [
    { field: 'roadId', headerName: 'Road ID', width: 100 },
    { field: 'name', headerName: 'Road Name', width: 200 },
    { field: 'cwayNo', width: 100 },
    { field: 'cwayStart', headerName: 'Cway Start (m)', width: 100 },
    { field: 'cwayMid', headerName: 'Cway Mid (m)', width: 100 },
    { field: 'cwayEnd', headerName: 'Cway End (m)', width: 100 },
    {
      field: 'latestCountDate',
      width: 100,
      headerName: 'Count Date',
      valueFormatter: (params) => dateFormatter(params.value),
    },
    { field: 'latestCount', width: 100, headerName: 'Latest Count' },
    { field: 'calculatedEstimate', headerName: 'AADT Estimate', width: 100 },
    {
      field: 'calculationMethod', wrapText: false, width: 150, headerName: 'Derived From',
    },
    {
      field: 'estimationIndicator', wrapText: false, width: 150, headerName: 'Indicator',
    },
    {
      field: 'calculatedEstimateDate',
      width: 100,
      valueFormatter: (params) => dateFormatter(params.value),
      headerName: 'Estimate Date',
    },
    agTrafficMixColdef('calculatedPcCar', '%Car'),
    agTrafficMixColdef('calculatedPcLcv', '%LCV'),
    agTrafficMixColdef('calculatedPcMcv', '%MCV'),
    agTrafficMixColdef('calculatedPcHcvI', '%HCVI'),
    agTrafficMixColdef('calculatedPcHcvII', '%HCVII'),
    agTrafficMixColdef('calculatedPcBus', '%Buses'),
    agTrafficMixColdef('calculatedPcHeavy', '%Heavy'),
    { field: 'latest', width: 80 },
    { field: 'countStatus', width: 70, headerName: 'Count Status' },
    { field: 'direction', width: 70 },
    { field: 'urbanRural', width: 70, headerName: 'Urban Rural' },
    { field: 'roadType', width: 100, headerName: 'Road Type' },
    { field: 'ownerType', width: 100, headerName: 'Owner Type' },
    { field: 'pavementType', width: 100, headerName: 'Pavement Type' },
    { field: 'pavementUse', width: 100, headerName: 'Pavement Use' },
    { field: 'category', headerName: 'ONRC Category', width: 140 },
    { field: 'trafficGroup' },
    { field: 'hierarchy', width: 100, headerName: 'Cway Hierarchy' },
    { field: 'cwayArea', width: 100, headerName: 'Cway Area' },
  ];

  return (
    <TceGrid
      columnDefs={columnDefs}
      isLoading={false}
      name={screenName}
      hasDownload={false}
    />
  );
}

function Step5({ canSeeResults }) {
  if (!canSeeResults) {
    return (
      <Container
        maxW="fit-content"
        background="gray.50"
        mt={50}
        mb={50}
        p="10"
        borderRadius="md"
      >
        <VStack h="full" fontSize="20px">
          <Text>
            Results have not been generated yet.
          </Text>
        </VStack>
      </Container>
    );
  }

  return (
    <Flex direction="column" width="100%">
      <Filter name={screenName} />
      <DataTable />
    </Flex>
  );
}

export default Step5;
