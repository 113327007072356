import { Flex } from '@chakra-ui/react';
import Filter from '@tce/components/pages/traffic-count-estimates/components/wizard/Filter';
import { Step1Filter } from '@tce/components/pages/traffic-count-estimates/components/wizard/step1/Step1Filter';
import { agEditableColdef, dateFormatter, numberFormatter } from '@tce/components/pages/traffic-count-estimates/components/wizard/table';
import TceGrid from '@tce/components/pages/traffic-count-estimates/components/wizard/TceGrid';
import { tceWizardFilterOnlyRoadSectionsWithCounts } from '@tce/state/tce';
import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

const screenName = 'step1';

function DataTable() {
  const setOnlyRoadSectionsWithCounts = useSetRecoilState(
    tceWizardFilterOnlyRoadSectionsWithCounts(screenName),
  );

  const editableFlagName = 'reviewTrafficMix';

  const columnDefs = useMemo(() => [
    { field: 'roadId', headerName: 'Road ID', width: 80 },
    { field: 'name', headerName: 'Road Name', width: 200 },
    { field: 'cwayNo', headerName: 'Cway Number', width: 120 },
    { field: 'cwayStart', headerName: 'Cway Start (m)', width: 120 },
    { field: 'cwayMid', headerName: 'Cway Mid (m)', width: 100 },
    { field: 'cwayEnd', headerName: 'Cway End (m)', width: 120 },
    {
      field: 'errorWarning', headerName: 'Error Warning', wrapText: false, width: 400,
    },
    { field: 'latestCount', headerName: 'Latest Count', width: 100 },
    {
      field: 'latestCountDate',
      headerName: 'Latest Count Date',
      wrapText: true,
      width: 100,
      valueFormatter: (params) => dateFormatter(params.value),
    },
    { field: 'previousCount', headerName: 'Previous Count', width: 100 },
    {
      field: 'previousCountDate', headerName: 'Previous Count Date', width: 100, valueFormatter: (params) => dateFormatter(params.value),
    },
    agEditableColdef('pcCar', editableFlagName, '%Car', [0, 100]),
    agEditableColdef('pcLcv', editableFlagName, '%LCV', [0, 100]),
    agEditableColdef('pcMcv', editableFlagName, '%MCV', [0, 100]),
    agEditableColdef('pcHcvI', editableFlagName, '%HCVI', [0, 100]),
    agEditableColdef('pcHcvII', editableFlagName, '%HCVII', [0, 100]),
    agEditableColdef('pcBus', editableFlagName, '%Buses', [0, 100]),
    {
      field: 'pcHeavy', headerName: '%Heavy', valueFormatter: numberFormatter, width: 90,
    },
    { field: 'category', headerName: 'ONRC Category' },
    { field: 'trafficGroup', headerName: 'Traffic Group' },
    { field: 'hierarchy', headerName: 'Cway Hierarchy' },
    { field: 'urbanRural', width: 70, headerName: 'Urban Rural' },
  ], [editableFlagName]);

  useEffect(() => {
    setOnlyRoadSectionsWithCounts(true);
  }, [setOnlyRoadSectionsWithCounts]);

  return (
    <TceGrid
      columnDefs={columnDefs}
      name={screenName}
    />
  );
}

export default function Step1() {
  return (
    <Flex direction="column" width="100%">
      <Filter name={screenName}>
        <Step1Filter name={screenName} />
      </Filter>
      <DataTable />
    </Flex>
  );
}
