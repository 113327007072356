import {
  Flex,
  Box,
  Text,
  IconButton,
  Icon,
  Button,
  Progress,
} from '@chakra-ui/react';
import { QUARTERS } from '@src/modules/performance/shared/constants';
import { roundKpiValue } from '@src/modules/performance/shared/helpers';
import { FiAlertCircle, FiEdit3, FiPlus } from 'react-icons/fi';

export default function TtmCard({ item, onEdit }) {
  const quarter = QUARTERS.find((q) => q.id === item.quarter);
  const renderPhaseDetails = () => {
    switch (item.phase) {
      case 'before':
        return (
          <Box>
            <Flex justify="space-between" align="center">
              <Text fontSize="sm">
                Opens on
                {' '}
                {new Date(item.entryStartDate).toLocaleDateString('en-NZ', { dateStyle: 'medium' })}
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                {item.daysUntilStart}
                {' '}
                {item.daysUntilStart > 1 ? 'days' : 'day'}
                {' '}
                until open
              </Text>
            </Flex>
          </Box>
        );
      case 'during':
        return (
          <Box>
            <Flex justify="space-between" align="center">
              <Text fontSize="sm">
                Closes on
                {' '}
                {new Date(item.entryEndDate).toLocaleDateString('en-NZ', { dateStyle: 'medium' })}
              </Text>
              {item.daysUntilEnd > 0 ? (
                <Text fontSize="sm" fontWeight="bold">
                  {item.daysUntilEnd}
                  {' '}
                  {item.daysUntilEnd > 1 ? 'days' : 'day'}
                  {' '}
                  left
                </Text>
              ) : (
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Last day today
                </Text>
              )}
            </Flex>
            <Progress
              borderRadius="full"
              value={item.progress}
              colorScheme="red"
              size="sm"
              mt={2}
              width="100%"
            />
          </Box>
        );
      case 'after':
        return (
          <Flex border="1px solid" borderColor="red.100" p={2} borderRadius="base">
            <Icon as={FiAlertCircle} boxSize={4} color="red.600" />
            <Text color="red.600" fontSize="xs" ml={2}>
              Entry period has ended.
            </Text>
          </Flex>
        );
      default:
        return null;
    }
  };

  const renderEditableActions = () => {
    if (item.id && item.isEditable && item.phase === 'during') {
      return (
        <IconButton
          aria-label="Edit"
          title="Edit Result"
          icon={<Icon as={FiEdit3} boxSize={5} />}
          size="md"
          variant="ghost"
          colorScheme="brand.orange"
          onClick={() => onEdit()}
        />
      );
    }
    return null;
  };

  const renderAddResultButton = () => {
    if (!item.id) {
      return (
        <Button
          leftIcon={<Icon as={FiPlus} boxSize={4} />}
          colorScheme="orange"
          title="Add Result"
          aria-label="Add Result"
          onClick={() => onEdit(item)}
          isDisabled={item.phase !== 'during'}
          size="sm"
        >
          Add Result
        </Button>
      );
    }
    return null;
  };

  if (!item.isPastQuarter && !item.isEditable) {
    return (
      <Flex
        background="gray.100"
        align="center"
        justify="center"
        borderRadius="base"
        flexDirection="column"
        p={8}
      >
        <Text fontWeight="bold" fontSize="md" color="gray.400">
          {`${quarter?.label} (${quarter?.startMonth}-${quarter?.endMonth})`}
        </Text>
      </Flex>
    );
  }

  if (item.isPastQuarter && !item.id) {
    return (
      <Flex
        background="gray.100"
        align="center"
        justify="center"
        borderRadius="base"
        flexDirection="column"
        p={8}
      >
        <Text fontWeight="bold" fontSize="md">
          No data entered
        </Text>
        <Text fontWeight="bold" fontSize="md">
          {`${quarter?.label} (${quarter?.startMonth}-${quarter?.endMonth})`}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      background="white"
      boxShadow="base"
      borderRadius="base"
      padding="4"
      flexDir="column"
      justifyContent="space-between"
    >
      <Flex justify="space-between" align="center" mb={4}>
        <Text fontSize="lg" fontWeight="bold">
          {`${quarter?.label} (${quarter?.startMonth}-${quarter?.endMonth})`}
        </Text>
        {renderAddResultButton()}
        {renderEditableActions()}
      </Flex>

      {!item.id ? (
        renderPhaseDetails()
      ) : (
        <Box>
          <Text fontSize="xs" mb={4}>Costs are year to date.  Site counts are for the quarter.</Text>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm">NLTP YTD Cost ($000&apos;s)</Text>
            <Text fontSize="sm" fontWeight="bold">
              {item.nltpCost !== null ? `$${item.nltpCost?.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              })}` : '-'}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm">TTM YTD Cost ($000&apos;s)</Text>
            <Text fontSize="sm" fontWeight="bold">
              {item.ttmCost !== null ? `$${item.ttmCost?.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              })}` : '-'}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm">TTM Cost %</Text>
            <Text fontSize="sm" fontWeight="bold">
              {item.ttmPercentage !== null ? `${roundKpiValue(item.ttmPercentage, 5)}%` : '-'}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm">Confidence in TTM Cost</Text>
            <Text fontSize="sm" fontWeight="bold">{item.ttmCostConfidence ? `${item.ttmCostConfidence} / 5` : '-'}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm">Total Site Inspections for the quarter</Text>
            <Text fontSize="sm" fontWeight="bold">{item.completedSiteInspections}</Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm">Redundant Sites for the quarter</Text>
            <Text fontSize="sm" fontWeight="bold">{item.unattendedSiteInspections}</Text>
          </Flex>
          {item.phase === 'during' && item.isEditable && (
            <Box mt={4}>
              {renderPhaseDetails()}
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
}
