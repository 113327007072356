export const QUARTERS = [
  {
    id: 1, label: 'Q1', startMonth: 'Jul', endMonth: 'Sep',
  },
  {
    id: 2, label: 'Q2', startMonth: 'Oct', endMonth: 'Dec',
  },
  {
    id: 3, label: 'Q3', startMonth: 'Jan', endMonth: 'Mar',
  },
  {
    id: 4, label: 'Q4', startMonth: 'Apr', endMonth: 'Jun',
  },
];

export const TTM_SITE_STATUSES = [
  { id: 1, label: 'Inactive' },
  { id: 2, label: 'Active' },
  { id: 3, label: 'Unattended' },
  { id: 4, label: 'Not Necessary or Redundant' },
];

export const TrafficLightColours = {
  GREEN: '#5FAB12',
  YELLOW: '#F7C622',
  ORANGE: '#F66F22',
  RED: '#C01F24',
  GREY: '#b9bfc6',
};

export const SplitCircleColours = {
  BLUE: '#23aefe',
  LIGHT_BLUE: '#7ed2ff',
  DARK_BLUE: '#0083CB',
};

// Note: It is important this order remains as it is used to map the indicator text base on values

export const overallActivityAssessmentsLegend = [
  { label: 'Not Assessed', color: TrafficLightColours.GREY },
  { label: 'Good/Effective', color: TrafficLightColours.GREEN },
  { label: 'Fit for purpose/developing', color: TrafficLightColours.YELLOW },
  { label: 'Room for Improvement', color: TrafficLightColours.ORANGE },
  { label: 'Unacceptable/basic', color: TrafficLightColours.RED },
];

export const activityAssessmentsLegend = [
  { label: 'Not Assessed', color: TrafficLightColours.GREY },
  { label: 'Good', color: TrafficLightColours.GREEN },
  { label: 'Fit for purpose', color: TrafficLightColours.YELLOW },
  { label: 'Room for Improvement', color: TrafficLightColours.ORANGE },
];

export const smartBuyerAssessmentsLegend = [
  { label: 'Not Assessed', color: TrafficLightColours.GREY },
  { label: 'A Smart Buyer', color: TrafficLightColours.GREEN },
  { label: 'Developing', color: TrafficLightColours.YELLOW },
  { label: 'Limited', color: TrafficLightColours.ORANGE },
  { label: 'Basic', color: TrafficLightColours.RED },
];

export const coassuranceAssessmentLegend = [
  { label: 'Not Available', color: TrafficLightColours.GREY },
  { label: 'Unacceptable', color: TrafficLightColours.RED },
  { label: 'Significant improvement needed', color: TrafficLightColours.ORANGE },
  { label: 'Some improvement needed', color: TrafficLightColours.YELLOW },
  { label: 'Effective', color: TrafficLightColours.GREEN },
];

export const amenityAndConditionLegend = [
  { label: 'Increasing trend of 1% or greater', color: TrafficLightColours.GREEN },
  { label: 'Between a 1% decrease and 1% increase', color: TrafficLightColours.YELLOW },
  { label: 'Decreasing trend of 1% or greater', color: TrafficLightColours.RED },
];

export const safetyLegend = [
  { label: 'Decreasing trend of 1% or greater', color: TrafficLightColours.GREEN },
  { label: 'Between a 1% decrease and 1% increase', color: TrafficLightColours.YELLOW },
  { label: 'Increasing trend of 1% or greater', color: TrafficLightColours.RED },
];

export const deliveryPerformanceLegend = [
  { label: 'Less than 10% variance', color: TrafficLightColours.GREEN },
  { label: 'Between a 10% and 20% variance', color: TrafficLightColours.YELLOW },
  { label: 'Greater than 20% variance', color: TrafficLightColours.RED },
];

export const publicTransportLegend = [
  { label: 'Increasing trend of 1% or greater', color: TrafficLightColours.GREEN },
  { label: 'Between a 1% decrease and 1% increase', color: TrafficLightColours.YELLOW },
  { label: 'Decreasing trend of 1% or greater', color: TrafficLightColours.RED },
];

export const servicePerformanceLegend = [
  { label: 'Not Reported', color: TrafficLightColours.GREY },
  { label: 'Target achieved', color: TrafficLightColours.GREEN },
  { label: 'Target partially achieved', color: TrafficLightColours.ORANGE },
  { label: 'Target not achieved', color: TrafficLightColours.RED },
];

export const servicePerformanceKpiLegend = [
  { label: '80% or more', color: TrafficLightColours.GREEN },
  { label: '60% to 80%', color: TrafficLightColours.YELLOW },
  { label: 'Less than 60%', color: TrafficLightColours.RED },
];

export const e3Legend = [
  { label: 'Higher within the peer group', color: SplitCircleColours.DARK_BLUE },
  { label: 'Medium within the peer group', color: SplitCircleColours.BLUE },
  { label: 'Lower within the peer group', color: SplitCircleColours.LIGHT_BLUE },
];

export const stateHighwaysPotholeRepairsLegend = [
  { label: 'Greater than or equal to 90%', color: TrafficLightColours.GREEN },
  { label: 'Less than 90%', color: TrafficLightColours.RED },
];

export const temporaryTrafficManagementLegend = [
  { label: 'Less than 10% variance', color: TrafficLightColours.GREEN },
  { label: 'Between 10% and 20% variance', color: TrafficLightColours.YELLOW },
  { label: 'Greater than 20% variance', color: TrafficLightColours.RED },
];
