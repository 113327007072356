import {
  activityAssessmentsLegend, overallActivityAssessmentsLegend, smartBuyerAssessmentsLegend, coassuranceAssessmentLegend,
} from './constants';
/* map the value the specified colour range */
const COLOUR_RANGE_MAP = {
  safety: {
    colours: ['GREEN', 'LIGHTGREEN', 'YELLOW', 'ORANGE', 'RED'],
    ranges: [-1000, 5, 10, 15, 30, 1000],
  },
  achievements: {
    colours: ['GREEN', 'YELLOW', 'RED'],
    ranges: [-5, 1, 5],
  },
  activity: {
    colours: ['GREY', 'GREEN', 'YELLOW', 'ORANGE', 'RED'],
    ranges: [-10, 0, 1, 2, 3, 10],
  },
  coassurance: {
    colours: ['GREY', 'RED', 'ORANGE', 'YELLOW', 'GREEN'],
    ranges: [-10, 0, 0.25, 0.5, 0.75, 10],
  },
  // servicePerformance: {
  //   pass: 'GREEN',
  //   partial: 'ORANGE',
  //   fail: 'RED',
  //   na: 'GREY',
  //   'n/a': 'GREY',
  // },
  dataQuality: {
    colours: ['DQCOLOR1', 'DQCOLOR2', 'DQCOLOR3', 'DQCOLOR4'],
    ranges: [-1, 49, 69, 79, 100],
  },
  deliveryPerformance: {
    colours: ['GREEN', 'YELLOW', 'RED'],
    ranges: [-1, 9.99, 19.99, 1000],
  },
  temporaryTrafficManagement: {
    colours: ['GREEN', 'YELLOW', 'RED'],
    ranges: [-1, 9.99, 19.99, 5000],
  },
  stateHighwaysPotholeRepairsKpi: {
    colours: ['RED', 'GREEN'],
    ranges: [-1, 89.99, 200],
  },
  stateHighwaysPotholeRepairsHighVolume: {
    colours: ['RED', 'GREEN'],
    ranges: [-1, 94.99, 200],
  },
  stateHighwaysPotholeRepairsRegional: {
    colours: ['RED', 'GREEN'],
    ranges: [-1, 84.99, 200],
  },
  servicePerformanceKpi: {
    colours: ['RED', 'YELLOW', 'GREEN'],
    ranges: [-1, 59.99, 79.99, 500],
  },
};

const mapValueToColourRange = (value, rangeKey) => {
  const range = COLOUR_RANGE_MAP[rangeKey].ranges;

  for (let i = 0; i < range.length - 1; i++) {
    if (value > range[i] && value <= range[i + 1]) {
      return COLOUR_RANGE_MAP[rangeKey].colours[i];
    }
  }

  return 'none';
};

const mapActivityToText = (score, filter) => {
  switch (filter) {
    case 'Overall':
      return overallActivityAssessmentsLegend[score]?.label;
    case 'Smart_buyer_selfassessment':
      if (score >= 65) {
        return smartBuyerAssessmentsLegend[1].label;
      }
      if (score >= 55) {
        return smartBuyerAssessmentsLegend[2].label;
      }
      if (score >= 30) {
        return smartBuyerAssessmentsLegend[3].label;
      }
      if (score != null) {
        return smartBuyerAssessmentsLegend[4].label;
      }
      return smartBuyerAssessmentsLegend[0].label;
    default:
      if (score != null) {
        return activityAssessmentsLegend[score]?.label;
      }
      return activityAssessmentsLegend[0].label;
  }
};

const mapCoassuranceToText = (score) => {
  const index = score * 4;
  return coassuranceAssessmentLegend[index]?.label;
}; // map from 0.0 - 1.0 to 0 - 4

const mapCoInvestorLabel = (isTechnical) => {
  if (isTechnical) {
    return 'Technical Audit';
  }

  return 'Overall Rating';
};

const servicePerformance = {
  pass: 'Target Achieved',
  partial: 'Partially Achieved',
  fail: 'Target Not Achieved',
  na: 'Not Reported',
  'n/a': 'Not Reported',
  noData: 'No data',
};

const mapServicePerformanceToText = (value) => servicePerformance[value];

const roundKpiValue = (value, limit = 5, dp = 1, limitType = 'max') => {
  if (value === null || value === undefined) return null;
  const parsedValue = parseFloat(value);

  let roundedValue;
  if (limitType === 'min') {
    roundedValue = Math.abs(parsedValue) > limit ? parsedValue.toFixed(dp) : parsedValue.toFixed(0);
  } else {
    roundedValue = Math.abs(parsedValue) < limit ? parsedValue.toFixed(dp) : parsedValue.toFixed(0);
  }

  return roundedValue;
};

export {
  mapActivityToText,
  mapCoassuranceToText,
  mapCoInvestorLabel,
  mapServicePerformanceToText,
  mapValueToColourRange,
  roundKpiValue,
};
