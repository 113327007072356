import { uniq } from 'lodash';

// two indicators only use three colours
const calculateColourValuesThree = (data, max) => {
  const result = {
    colour: 'GREY',
    value: 0.0,
    max,
  };

  if (!data) return result;

  const colours = {
    green: 'green',
    yellow: 'yellow', // was not mapped in powerbi report
    orange: 'yellow',
    red: 'orange',
    grey: 'grey',
  };

  Object.keys(colours).forEach((col) => {
    if (data[col] !== null && data[col] > 0.0) {
      result.colour = colours[col].toLocaleUpperCase();
      result.value = data[col];
    }
  });

  if (data.source) {
    result.source = data.source;
  }

  return result;
};

const calculateColourValues = (data, max) => {
  const result = {
    colour: 'GREY',
    value: 0.0,
    max,
  };

  const colours = [
    'green',
    'yellow',
    'orange',
    'red',
    'grey',
  ];

  colours.forEach((col) => {
    if (data[col] !== null && data[col] > 0.0) {
      result.colour = col.toLocaleUpperCase();
      result.value = data[col];
    }
  });

  if (data.source) {
    result.source = data.source;
  }

  return result;
};

const convertDQAssetManagement = (data) => {
  const years = [];
  const am = [];
  const score = [];
  const peer_am = [];
  const national_am = [];

  if (data) {
    data.forEach((d) => {
      years.push(d.Year);
      am.push(d.AM);
      score.push(d.Score);
      peer_am.push(d.Peer_AM);
      national_am.push(d.National_AM);
    });
  }

  return {
    years,
    values: [
      // ta
      am,
      // am score
      score,
      // national
      national_am,
      // peer group
      peer_am,
    ],
  };
};

const ResultToColour = {
  pass: 'GREEN',
  partial: 'ORANGE',
  fail: 'RED',
  na: 'GREY',
  'n/a': 'GREY',
};

const convertServicePerformance = (data) => {
  if (!data || data.length < 1) {
    return null;
  }
  const latestLockYear = data[0].LockYear;
  // Need to report on 2 3 year cycles i.e 2018-2021 and 2021-2024 etc
  // Find the current year based on the latest lock year
  const currentYear = parseInt(latestLockYear.split('/')[0], 10);
  // Find the start year of the first cycle
  // If the current year is a start of a cycle then we need to go back 3 years
  // Otherwise we go back 4 years plus the remainder of the current year
  const startYear = currentYear % 3 === 2 ? currentYear - 3 : currentYear - (currentYear % 3) - 4;

  const filteredData = data.filter((d) => parseInt(d.Year, 10) > startYear);

  const allLockyears = filteredData.map((a) => a.FY);
  const distinctLockyears = [...new Set(allLockyears)].sort();

  // Display the 10 year LTP titles
  const firstCycleStartDate = startYear;
  const secondCycleStartDate = firstCycleStartDate + 3;
  const firstCycleDateRange = `${firstCycleStartDate}-${firstCycleStartDate - 1990}`;
  const secondCycleDateRange = `${secondCycleStartDate}-${secondCycleStartDate - 1990}`;

  const allTypes = filteredData.map((a) => a.Type_name);

  const distinct_types = [...new Set(allTypes)]; // should be sorted by type

  const rows = [];

  distinct_types.forEach((d) => {
    rows.push({
      title: d,
      cells: [],
    });
  });

  distinctLockyears.forEach((lockyear) => {
    rows.forEach((row) => {
      const item = filteredData.find((fd) => fd.FY === lockyear && fd.Type_name === row.title);
      if (item) {
        row.cells.push(ResultToColour[item.Result.toLowerCase()]);
      } else {
        row.cells.push('GREY');
      }
    });
  });

  return {
    headers: [
      {
        text: '',
        colspan: 1,
      },
      {
        text: `${firstCycleDateRange} Long Term Plan`,
        colspan: 3,
      },
      {
        text: `${secondCycleDateRange} Long Term Plan`,
        colspan: 3,
      },
    ],
    subheaders: [
      'Provision of roads and footpaths',
      ...distinctLockyears,
    ],
    rows,
    legend: [
      {
        colour: 'GREEN',
        text: 'Target Achieved',
      },
      {
        colour: 'ORANGE',
        text: 'Partially Achieved',
      },
      {
        colour: 'RED',
        text: 'Target Not Achieved',
      },
      {
        colour: 'GREY',
        text: 'Not Reported',
      },
    ],
  };
};

// mapping with gaps in it...yeah, nah.
const mapScoresToColours = (c) => {
  if (c >= 0.99 && c <= 1.0) return 'GREEN';
  if (c >= 0.74 && c <= 0.76) return 'YELLOW';
  if (c >= 0.49 && c <= 0.51) return 'ORANGE';
  if (c >= 0.24 && c <= 0.26) return 'RED';
  return 'GREY';
};

const convertAudit = (data, title) => {
  if (!data || data.length < 1) {
    return {
      title: 'No data available',
      labels: [],
      values: [],
      grading: 0,
    };
  }

  // sort by first order asc
  const sortedData = data.sort((a, b) => a.FirstOrder - b.FirstOrder);
  const { grading, date } = sortedData[0];
  const labels = sortedData.map((sd) => sd.ColumnTitle);
  const values = sortedData.map((sd) => mapScoresToColours(sd.ColourScore));

  let gradingtype = 0;
  if (grading.toLowerCase().indexOf('four') > -1) {
    gradingtype = 4;
  }
  if (grading.toLowerCase().indexOf('three') > -1) {
    gradingtype = 3;
  }

  return {
    title: `${title} ${grading}`,
    labels,
    values,
    grading: gradingtype,
    date,
  };
};

const convertAreaGraph = (data, max) => {
  const allYears = data.map((a) => a.Year);
  const distinct_years = [...new Set(allYears)].sort();
  const values = [];

  for (let j = 0; j < max; j++) {
    values.push([]);
  }

  distinct_years.forEach((year) => {
    const filtered = data.filter((d) => d.Year === year).sort((a, b) => a.Type2 - b.Type2);
    // aggregate by type values
    for (let i = 0; i < max; i++) {
      const toSum = filtered.filter((item) => item.Type2 === i + 1).map((item) => item.Value);
      let sum = toSum.length > 0 ? toSum.reduce((prev, next) => prev + next) : 0;

      if (sum !== 0) {
        sum /= 1000000;
      }

      values[i].push(sum);
    }
  });

  return {
    // years
    years: distinct_years,
    // data
    values,
  };
};

const convertAreaGraph2 = (data, field, groupings) => {
  const allYears = data.map((a) => a.Year);
  const distinct_years = [...new Set(allYears)].sort();
  const values = [];

  for (let j = 0; j < groupings.length; j++) {
    values.push([]);
  }

  distinct_years.forEach((year) => {
    const filtered = data.filter((d) => d.Year === year).sort((a, b) => a.Type2 - b.Type2);
    // aggregate by authority
    for (let i = 0; i < groupings.length; i++) {
      let sum = filtered.filter((item) => item[field].toLowerCase() === groupings[i].toLowerCase()).map((item) => item.Value).reduce((prev, next) => prev + next);

      sum /= 1000000;

      values[i].push(sum);
    }
  });

  return {
    // years
    years: distinct_years,
    // data
    values,
  };
};

const convertAreaGraph3 = (data, field, groupings, valueField) => {
  const allYears = data.map((a) => a.Year);
  const distinct_years = [...new Set(allYears)].sort();
  const values = [];

  for (let j = 0; j < groupings.length; j++) {
    values.push([]);
  }

  distinct_years.forEach((year) => {
    const filtered = data.filter((d) => d.Year === year);
    // aggregate by grouping
    for (let i = 0; i < groupings.length; i++) {
      const groupedfiltered = filtered.filter((item) => item[field].toLowerCase() === groupings[i].toLowerCase());
      const mapped = groupedfiltered.map((item) => item[valueField]);
      let sum = null;
      if (mapped.length > 0) {
        sum = mapped.reduce((prev, next) => prev + next);
      }
      values[i].push(sum);
    }
  });

  return {
    // years
    years: distinct_years,
    // data
    values,
  };
};

const findMax = (data, field) => {
  const values = data.map((row) => row[field]);
  return Math.max(...values);
};

const convertAreaLineGraph = (data, tafield, pgfield, divideBy, multiplyBy) => {
  const allYears = data.map((a) => a.Year);
  const distinct_years = [...new Set(allYears)].sort();
  const values = [];
  let adjustYmax = null;

  if (divideBy) {
    // lets find the max and see if it is more than divideBy
    const taMax = findMax(data, tafield);
    const pgMax = findMax(data, pgfield);

    const lowMax = findMax(data, 'LowPercentile');
    const highMax = findMax(data, 'HighPercentile');

    const max = Math.max(Math.max(taMax, pgMax), Math.max(lowMax, highMax));

    if (max > 0 && max < divideBy) {
      adjustYmax = max / divideBy;
    }
  }

  for (let j = 0; j < 4; j++) {
    values.push([]);
  }

  distinct_years.forEach((year) => {
    const row = data.find((d) => d.Year === year);
    if (divideBy) {
      // TA - fund value
      values[0].push(divideBy > 0 ? row[tafield] / divideBy : row[tafield]);
      // peer group
      values[1].push(divideBy > 0 ? row[pgfield] / divideBy : row[pgfield]);

      // grey area
      // low percentile
      values[2].push(divideBy > 0 ? row.LowPercentile / divideBy : row.LowPercentile);
      // high percentile
      values[3].push(divideBy > 0 ? row.HighPercentile / divideBy : row.HighPercentile);
    } else if (multiplyBy) {
      // TA - fund value
      values[0].push(multiplyBy > 0 ? row[tafield] * multiplyBy : row[tafield]);
      // peer group
      values[1].push(multiplyBy > 0 ? row[pgfield] * multiplyBy : row[pgfield]);

      // grey area
      // low percentile
      values[2].push(multiplyBy > 0 ? row.LowPercentile * multiplyBy : row.LowPercentile);
      // high percentile
      values[3].push(multiplyBy > 0 ? row.HighPercentile * multiplyBy : row.HighPercentile);
    } else {
      // TA - fund value
      values[0].push(row[tafield]);
      // peer group
      values[1].push(row[pgfield]);

      // grey area
      // low percentile
      values[2].push(row.LowPercentile);
      // high percentile
      values[3].push(row.HighPercentile);
    }
  });

  return {
    // years
    years: distinct_years,
    // data
    values,
    adjustYmax,
  };
};

const convertLineGraph = (data, fields, divideBy, multiplyBy, zeroFilter) => {
  const allYears = data.map((a) => a.Year);
  const distinct_years = [...new Set(allYears)].sort();
  const values = [];

  for (let j = 0; j < fields.length; j++) {
    values.push([]);
  }

  distinct_years.forEach((year) => {
    const row = data.find((d) => d.Year === year);

    fields.forEach((field, index) => {
      if (row[field] !== null) {
        if (zeroFilter && parseInt(row[field], 10) === 0) {
          values[index].push(null);
        } else if (divideBy) {
          values[index].push(row[field] / divideBy);
        } else if (multiplyBy) {
          values[index].push(row[field] * multiplyBy);
        } else {
          values[index].push(row[field]);
        }
      } else {
        values[index].push(null);
      }
    });
  });

  return {
    // years
    years: distinct_years,
    // data
    values,
  };
};

// loop over the data and return values grouped by filterfield
const convertLineGraphFilter = (data, valueField, filterField, fields, multiplyBy) => {
  const allYears = data.map((a) => a.Year);
  const distinct_years = [...new Set(allYears)].sort();
  const values = [];

  for (let j = 0; j < fields.length; j++) {
    values.push([]);
  }

  distinct_years.forEach((year) => {
    const rows = data.filter((d) => d.Year === year);
    fields.forEach((field, index) => {
      rows.forEach((row) => {
        if (row[filterField] === field) {
          values[index].push(multiplyBy > 0 ? row[valueField] * multiplyBy : row[valueField]);
        }
      });
    });
  });

  return {
    // years
    years: distinct_years,
    // data
    values,
  };
};

const convertGroupedBarchart = (data, fields) => {
  const allYears = data.map((a) => a.Year);
  const distinct_years = [...new Set(allYears)].sort();
  const values = [];

  for (let j = 0; j < fields.length; j++) {
    values.push([]);
  }

  distinct_years.forEach((year) => {
    const row = data.find((d) => d.Year === year);

    fields.forEach((field, index) => {
      if (row[field] !== null) {
        values[index].push(row[field]);
      } else {
        values[index].push(null);
      }
    });
  });

  return {
    categories: distinct_years,
    values,
  };
};

const convertGroupedBarchartByCategory = (data, fields, categoryField) => {
  const allCategories = data.map((a) => a[categoryField]);
  const categories = [...new Set(allCategories)];
  const values = [];

  for (let j = 0; j < fields.length; j++) {
    values.push([]);
  }

  categories.forEach((cat) => {
    const row = data.find((d) => d[categoryField] === cat);

    fields.forEach((field, index) => {
      if (row[field]) {
        values[index].push(row[field]);
      } else {
        values[index].push(null);
      }
    });
  });

  return {
    categories,
    values,
  };
};

const convertTornadoChart = (data, fields, categoryField, divideBy, multiplyBy) => {
  const allCategories = data.map((a) => a[categoryField]);
  const categories = [...new Set(allCategories)];

  const values = [];

  for (let j = 0; j < fields.length; j++) {
    values.push([]);
  }

  categories.forEach((cat) => {
    const row = data.find((d) => d[categoryField] === cat);

    fields.forEach((field, index) => {
      if (row[field]) {
        if (divideBy) {
          const calcValue = (row[field] / (typeof divideBy === 'string' ? row[divideBy] : divideBy)) * (multiplyBy || 1);
          values[index].push(calcValue);
        } else if (multiplyBy) {
          values[index].push(row[field] * multiplyBy);
        } else {
          values[index].push(row[field]);
        }
      } else {
        values[index].push(null);
      }
    });
  });

  return {
    categories,
    values,
  };
};

// divideby and multiplyby are arrays here
const convertDoubleAxisBarchart = (data, fields, categoryField, divideBy, multiplyBy, autoFormat) => {
  const allCategories = data.map((a) => a[categoryField]);
  const categories = [...new Set(allCategories)];

  const ids = ['left', 'right'];
  const values = [];

  for (let j = 0; j < fields.length; j++) {
    values.push({
      data: [],
      id: ids[j],
    });
  }

  const div = [1, 1];
  const max = [0, 0];
  const units = ['', ''];
  // we want to auto divide each dataset so that they
  // display as 1k, 1m, 1b etc in the graph

  // we can't use a string number formatter here as we want a consistent
  // format for all the units
  if (autoFormat) {
    // establish what the largest value is
    categories.forEach((cat) => {
      const row = data.find((d) => d[categoryField] === cat);
      fields.forEach((field, index) => {
        if (row[field]) {
          max[index] = Math.max(row[field], max[index]);
        }
      });
    });

    // determine a single divisor and unit to use across each dataset
    max.forEach((value, index) => {
      if (value > 5000) {
        div[index] = 1000;
        units[index] = 'K';
      }
      if (value > 1000000) {
        div[index] = 1000000;
        units[index] = 'M';
      }
      if (value > 1000000000) {
        div[index] = 1000000000;
        units[index] = 'B';
      }
    });
  }

  categories.forEach((cat) => {
    const row = data.find((d) => d[categoryField] === cat);
    fields.forEach((field, index) => {
      if (row[field]) {
        if (!autoFormat) {
          if (divideBy && divideBy[index]) {
            values[index].data.push(row[field] / divideBy[index]);
          } else if (multiplyBy && multiplyBy[index]) {
            values[index].data.push(row[field] * multiplyBy[index]);
          } else {
            values[index].data.push(row[field]);
          }
        } else {
          // autoformat
          values[index].data.push(row[field] !== 0.0 ? row[field] / div[index] : row[field]);
        }
      } else {
        values[index].data.push(null);
      }
    });
  });

  return {
    categories,
    values,
    units,
  };
};

const returnSingleValue = (data, field, divideBy, multiplyBy) => {
  // If no data is provided, return default value and indicate no data
  if (!data) {
    return {
      value: 0,
      nodata: true,
    };
  }

  let value;

  // Check if field is an array
  if (Array.isArray(field)) {
    // Map the field array to get corresponding values from data
    const values = field.map((f) => data[f]).filter((v) => v !== undefined && v !== null);
    // If no valid values are found, return default value and indicate no data
    if (values.length === 0) {
      return {
        value: 0,
        nodata: true,
      };
    }
    // Get the minimum value among the fields
    value = Math.min(...values);
  } else {
    // If field is a string, get the value directly from data
    value = data[field];
    // If the value is undefined, return default value and indicate no data
    if (value === undefined || value === null) {
      return {
        value: 0,
        nodata: true,
      };
    }
  }

  // If divideBy is provided and value is not zero, divide the value
  if (divideBy && value !== 0.0) {
    value /= divideBy;
  } else if (multiplyBy) {
    value *= multiplyBy;
  }

  // Return the calculated value and indicate data was found
  return {
    value,
    nodata: false,
  };
};

const convertGDPData = (data) => {
  const allCategories = data.map((a) => a.feature);
  const categories = [...new Set(allCategories)];

  const allYears = data.map((a) => a.year);
  const years = uniq(allYears);

  const values = [];
  for (let j = 0; j < categories.length; j++) {
    values.push([]);
  }

  // filter out the top five categories and sort by those
  // find the last year
  const lastYear = Math.max(...years);

  // get the category and dollar value for that last year and sort by dollars
  // (sort is descending, best comes first)
  const lastyrs = data.filter((row) => parseInt(row.year, 10) === parseInt(lastYear, 10)).sort((a, b) => {
    if (a.GDP > b.GDP) return -1;
    if (a.GDP < b.GDP) return 1;
    return 0;
  });

  // we only want the first 5 categories
  const top5Categories = lastyrs.slice(0, 5).map((l) => l.feature);

  // loop over data and populate the values
  years.forEach((year) => {
    const rows = data.filter((d) => d.year === year);
    top5Categories.forEach((cat, index) => {
      rows.forEach((row) => {
        if (row.feature === cat) {
          values[index].push(row.GDP > 0 ? row.GDP / 1000000 : null);
        }
      });
    });
  });

  return {
    years: [...years.slice(0, -1), [years.slice(-1)[0].toString(), '(Prov)']],
    categories: top5Categories,
    values: values.filter((v) => v.length > 0),
  };
};

export {
  calculateColourValuesThree,
  calculateColourValues,
  convertDQAssetManagement,
  convertServicePerformance,
  convertAudit,
  convertAreaGraph,
  convertAreaGraph2,
  convertAreaGraph3,
  convertAreaLineGraph,
  convertLineGraph,
  convertLineGraphFilter,
  convertGroupedBarchart,
  convertTornadoChart,
  convertDoubleAxisBarchart,
  convertGroupedBarchartByCategory,
  returnSingleValue,
  convertGDPData,
  // convertAreaGraph3
};
